<template>
  <div>
    <ValidationObserver ref="filterForm">
      <b-row>
        <b-col cols="12">
          <ValidationProvider name="file" :rules="'required'" v-slot="{ valid, errors }">
            <b-form-group :label="$t('excel_file')">
              <div class="d-flex custom-file-upload">
                <b-form-file v-model="form.file" :state="errors[0] ? false : (valid ? true : null)"
                             :placeholder="$t('select_file')" :drop-placeholder="$t('drop_file')"
                             ref="fileInput"></b-form-file>
                <b-button variant="outline-secondary" @click="$refs.fileInput.$el.childNodes[0].click();">{{
                    $t('browse') }}</b-button>
              </div>
              <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
            </b-form-group>
          </ValidationProvider>

        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="d-flex">
          <b-button @click="download('yoksis_service_bulk_delete')" variant="outline-secondary" class="mr-2">{{ $t('download_excel_template') }}</b-button>
          <b-button variant="primary" @click="save">
            {{ $t('import') }}
          </b-button>
        </b-col>
      </b-row>
    </ValidationObserver>
  </div>
</template>

<script>
import YoksisService from "@/services/YoksisService"
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import excelImportTemplate from "@/services/ExcelImportTemplate";

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      form: {},
    }
  },
  methods: {
    async save() {
      const isValid = await this.$refs.filterForm.validate()

      if (isValid) {
        const formData = new FormData();
        formData.append('file', this.form.file);

        YoksisService.bulkDeletePermitImport(formData)
            .then((response) => {
              this.$toast.success(this.$t('api.SUCCESS'))

            })
            .catch((error) => {
              this.showErrors(error)
            })
      }
    },
    download(code) {
      excelImportTemplate.downloadTemplate(code)
          .then(response => {
            this._downloadFile(response, code + '.xlsx')
          })
          .catch(error => {
            if (error.status == 422) {
              this.$toast.error(error.data.errors.file[0]);
            }
            if (error.status == 406) {
              this.$toast.error(this.$t('api.' + error.data.message));
            }
          });
    },
  }
}
</script>
