// Plugins
import i18n from '@/plugins/i18n'

export default function() {
    let reportTypes = [];

    reportTypes.push({
        value: "make-up-exam",
        text: i18n.t("make_up_additional_student_list"),
        permission: "makeupexam_import",
    });

    reportTypes.push({
        value: "student-course",
        text: i18n.t("student_course"),
        permission: "studentcourse_import",
    });

    reportTypes.push({
        value: "courses-name-import",
        text: i18n.t("update_student_course_name"),
        permission: "studentcourse_update",
    });

    reportTypes.push({
        value: "student-course-delete",
        text: i18n.t("student_course_delete"),
        permission: "studentcourse_bulkdelete",
    });

    reportTypes.push({
        value: "student-semester-status-update",
        text: i18n.t("student_semester_status_update"),
        permission: "studentsemester_updatestudentsemesterstatus",
    });

    reportTypes.push({
        value: "bulk-student-slot-update",
        text: i18n.t("bulk_student_slot_update"),
        permission: "studentslot_studentslotexcelimport",
    });

    reportTypes.push({
        value: "bulk-student-add-permit",
        text: i18n.t("bulk_yoksis_freeze_registration_data_transfer"),
        permission: "yoksisservice_bulkaddpermit",
    });

    reportTypes.push({
        value: "bulk-student-semester-update",
        text: i18n.t("batch_course_term_information_update"),
        permission: "studentcourse_bulkstudentcoursesemesterupdateimport",
    });

    reportTypes.push({
        value: "bulk-delete-permit",
        text: i18n.t("bulk_delete_permit"),
        permission: "yoksisservice_bulkdeletepermit",
    });

    return reportTypes;

}


